/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { MobileOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Login, request_for_otp } from "./api.js";
import logo from "./asset/logo.png";
import { message } from "antd";
import { GlobalContext } from "./GlobalContext.js";

function App() {
  const { user, setUser } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [office, setOffice] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user?.token) {
      navigate("/onboarding");
    }
  }, [user]);
  const onFinish = (values) => {
    sessionStorage.setItem("Candidate_values", JSON.stringify(values));
    values.id = searchParams.get("id");
    setloading(true);
    request_for_otp(values)
      .then((res) => {
        console.log(res);
        // setUser(res?.data?.items);
        sessionStorage.setItem(
          "candidate_id",
          JSON.stringify(res?.data?.candidate_id)
        );
        navigate("/otp");
        setloading(false);
        message.success(res.data.message);
      })
      .catch((err) => {
        setloading(false);
        //console.log(err.response.data.error);
        message.error(err.response.data.error ?? "Invalid credentials!");
      });
  };
  return (
    <div className="back">
      <div className="div-center">
        <div className="content">
          <div className="text-center">
            <img src={logo} alt=""></img>
          </div>

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              office_number: searchParams.get("office"),
              password: "",
              remember: false,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="office_number"
              rules={[
                {
                  required: true,
                  message: "Please input office number",
                },
              ]}
            >
              <Input
                prefix={<ShopOutlined className="site-form-item-icon" />}
                placeholder="Office number"
              />
            </Form.Item>
            <Form.Item
              name="mobile_number"
              rules={[
                {
                  required: true,
                  message: "Please input your mobile number",
                },
              ]}
            >
              <Input
                prefix={<MobileOutlined className="site-form-item-icon" />}
                type="number"
                placeholder="Mobile number"
              />
            </Form.Item>
           

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox style={{ color: "#fff" }}>Remember me</Checkbox>
              </Form.Item> */}

              <small className="login-form-forgot text-center">
              By clicking on the submit button above I authorize and give consent to being contacted by Excess Telecom via text messaging, email or  telephone. Message + data rates may apply. Reply STOP to unsubscribe.

              </small>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default App;
